<div class="grid
            grid-cols-1
            place-items-center
           "
>
  <div class="landingLogo bg-[length:60%]"></div>
  <div class="tracking-in-expand">
    <span class="landingTextGray !text-h4">Spare</span>
    <span class="landingTextGray !text-h4">Parts</span>
    <span class="text-focus-in landingTextGreen !text-h4">Recognition</span>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <div class="app-func">
      <div>
        <mat-icon
          aria-hidden="false"
          class="mat-icon-changed"
        >
        library_add
        </mat-icon>
      </div>
      <div class="card-content">
        <span style="display: inline-block;">
          Add a new spare part using a SAP <br /> material number and a picture.
        </span>
      </div>
      <div class="card-actions">
        <button
        id="home-buttons"
        mat-flat-button
        color="primary"
        class="!normal-case !min-w-[10rem]"
        (click)="redirectToAddPage()"
        >
        Add new
        </button>
      </div>
    </div>
    <div class="app-func">
      <div>
        <mat-icon
          aria-hidden="false"
          class="mat-icon-changed"
        >
          image_search
        </mat-icon>
      </div>
      <div class="card-content">
        <span style="display: inline-block; text-align: center;">
          Find a part using an image and get info <br />like the batch, location, bin among others.
        </span>
      </div>
      <div class="card-actions">
        <button
        id="home-buttons"
        mat-flat-button
        color="primary"
        class="!normal-case !min-w-[10rem]"
        (click)="redirectToFindPage()"
        >
        Find part
        </button>
      </div>
  </div>
  </div>
</div>


