import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    breakpoint: number = 1;

    constructor(private nav: Router) { }

    ngOnInit(): void {
        this.breakpoint = (window.innerWidth <= 780) ? 1 : 2;
    }

    redirectToAddPage() {
        this.nav.navigate(['/add-part']);
    }

    redirectToFindPage() {
        this.nav.navigate(['/find-part']);
    }

    onResize(event: any) {
        this.breakpoint = (event.target.innerWidth <= 780) ? 1 : 2;
    }
}
