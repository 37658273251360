import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PagenotFoundComponent } from './shared/pagenotfound/pagenotfound.component'
import { NavbarComponent } from './shared/navbar/navbar.component';

// import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NgxMatFileInputModule } from '@angular-material-components/file-input';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { NgxImageCompressService } from "ngx-image-compress";

import { authConfig } from './auth-config';
import {
    AuthConfig,
    OAuthModule,
    OAuthStorage
} from 'angular-oauth2-oidc';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/services/auth.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavbarComponent,
        PagenotFoundComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        OAuthModule.forRoot(),
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCardModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        // FlexLayoutModule,
        NgxMatFileInputModule,
        ReactiveFormsModule,
        MatIconModule,
        FormsModule,
        HttpClientModule,
        MatGridListModule,
        //StoreModule.forRoot({}, {}),
        //EffectsModule.forRoot([]),
        //SharedAzureAuthModule.forRoot(azureConfig),
    ],
    providers: [
        NgxImageCompressService,
        MatSnackBar,
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: ''},
        { provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    bootstrap: [AppComponent, /*MsalRedirectComponent*/]
})
export class AppModule { }

export function storageFactory(): OAuthStorage {
    //return sessionStorage;
    return localStorage;
}
